<template>
  <img src="/img/AFS-logo.png" alt="">
</template>

<script>
import VuesticLogo from '@/components/vuestic-logo'
export default {
  components:{VuesticLogo},
  props: {
    color: String,
  },
}
</script>

<style scoped>
  img {
    position: absolute;
    bottom: 10vh;
    width: 100%;
    border-radius: 50%;
    left: 40px;
    transform: scale(1.5) rotate(30deg);
    opacity: .2;
  }
  @media(max-width: 768px){
    img{
    bottom: 0;
    left: -10px;
    transform: scale(2.5) rotate(30deg);
    opacity: .2;
    }
  }
</style>
